exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-basel-tsx": () => import("./../../../src/pages/basel.tsx" /* webpackChunkName: "component---src-pages-basel-tsx" */),
  "component---src-pages-burnell-tsx": () => import("./../../../src/pages/burnell.tsx" /* webpackChunkName: "component---src-pages-burnell-tsx" */),
  "component---src-pages-carr-tsx": () => import("./../../../src/pages/carr.tsx" /* webpackChunkName: "component---src-pages-carr-tsx" */),
  "component---src-pages-culprit-tsx": () => import("./../../../src/pages/culprit.tsx" /* webpackChunkName: "component---src-pages-culprit-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-schlesinger-tsx": () => import("./../../../src/pages/schlesinger.tsx" /* webpackChunkName: "component---src-pages-schlesinger-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

